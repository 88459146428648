import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"
import { Link } from "gatsby"

// import People from '../images/people.jpg'
// import ThumbsUp from '../images/thumbup.jpg'
// import Fusion from "../images/f-logo.svg"
// import ContactUsMain from "../components/contactusmain"

const services = () => (
  <Layout>
    <Seo title="Services" />
    <HeroMini title="Services" />
    <main className="section has-text-black">
      <div className="container mb-6">
        <div className="columns">
          <div className="column is-one-third">
            <div className="card">
              <header style={{backgroundColor:"#009980"}} className="card-header">
                <p className="card-header-title has-text-white">Cloud</p>
              </header>
              <div className="card-content">
                <div className="content">
                  <p>Bespoke Azure web application function and AI search development</p>
                </div>
              </div>
              <Link to="/cloud-services" style={{color:"#009980"}}>
              <footer className="card-footer">
              <p className="card-footer-item is-size-5">Click for more details</p>
            </footer>
            </Link>
            </div>
          </div>

          <div className="column is-one-third">
            <div className="card">
              <header style={{backgroundColor:"#009980"}} className="card-header">
                <p className="card-header-title has-text-white">Web/Mobile</p>
              </header>
              <div className="card-content">
                <div className="content">
                  <p>Headless CMS and Static website development</p>
                </div>
              </div>
              <Link to="/web-services" style={{color:"#009980"}}>
              <footer className="card-footer">
              <p className="card-footer-item is-size-5">Click for more details</p>
            </footer>
            </Link>
            </div>
          </div>

          <div className="column is-one-third">
            <div className="card">
              <header style={{backgroundColor:"#009980"}} className="card-header">
                <p className="card-header-title has-text-white">Office 365</p>
              </header>
              <div className="card-content">
                <div className="content">
                  Fusion can help with purchase, set-up, training and support for Office apps, like Word, Excel, PowerPoint, and Outlook.  
                  <br />
                </div>
              </div>
              <Link to="/office-services" style={{color:"#009980"}}>
              <footer className="card-footer">
              <p className="card-footer-item is-size-5">Click for more details</p>
            </footer>
            </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-6">
        <div className="columns">
          <div className="column is-one-third">
            <div className="card">
              <header style={{backgroundColor:"#009980"}} className="card-header">
                <p className="card-header-title has-text-white">Facilitations</p>
              </header>
              <div className="card-content">
                <div className="content">
                Fusion can support department, team and group workshops; facilitating understanding, planning, decisions and action. Our approach ensures engagement, coordination and collaboration for processes, planning, projects and change.                  <br />
                </div>
              </div>
              <Link to="/facilitation-services" style={{color:"#009980"}}>
              <footer className="card-footer">
              <p className="card-footer-item is-size-5">Click for more details</p>
            </footer>
            </Link>
            </div>
          </div>

          <div className="column is-one-third">
            <div className="card">
              <header style={{backgroundColor:"#009980"}} className="card-header">
                <p className="card-header-title has-text-white">Training</p>
              </header>
              <div className="card-content">
                <div className="content">
                Fusion provide check-lists, cheat-sheets and tips on Microsoft Apps and and we are able to provide training on a one to one basis, or customised for your project or departmental teams, to ensure the best fit for your business.                  <br />
                </div>
              </div>
              <Link to="/training-services" style={{color:"#009980"}}>
              <footer className="card-footer">
              <p className="card-footer-item is-size-5">Click for more details</p>
            </footer>
            </Link>
            </div>
          </div>

          <div className="column is-one-third">
            <div className="card">
              <header style={{backgroundColor:"#009980"}} className="card-header">
                <p className="card-header-title has-text-white">Business Audit</p>
              </header>
              <div className="card-content">
                <div className="content">
                Fusion are expert in policies, processes, systems, governance and compliance and can help define, document, deliver and monitor a variety of business audit functions for Data Protection, Information Governance, Cyber Security, Business Continuity and Diaster Recovery and AML / CFT.                   <br />
                </div>
                </div>
              <Link to="/business-audit" style={{color:"#009980"}}>
              <footer className="card-footer">
                <p className="card-footer-item is-size-5">Click for more details</p>
            </footer>
            </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default services
